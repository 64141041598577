import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';
import { CoreModule } from '../core';
import { DcalComponent } from './dcal.component';
import { DrugMarginCalculatorComponent } from './drug-margin-calculator/drug-margin-calculator.component';
import { DrugMarginPdfComponent } from './drug-margin-pdf/drug-margin-pdf.component';

@NgModule({
  imports: [
    SharedModule,
    CoreModule,
  ],
  declarations: [
    DcalComponent,
    DrugMarginCalculatorComponent,
    DrugMarginPdfComponent,
  ],
  providers: []
})
export class DcalModule { }
