import {
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';

import {
  percentPattern,
  maxlength20charsPattern,
} from '@shared/utils';

export function PayerMixOrBilledUnitsValidator(): ValidatorFn {
  return (control: AbstractControl): {
    [key: string]: any
  } => {
    const currentValue = control.value;
    if (!currentValue || currentValue === '') {
      return null;
    } else if (currentValue.includes('%')) {
      return percentPattern.test(currentValue) ? null : {
        'pattern': true
      };
    } else {
      return maxlength20charsPattern.test(currentValue) ? null : {
        'maxlength': true
      };
    }
  };
}
