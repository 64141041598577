import { DrugProfile } from './drug-profile.model';
import * as _ from 'lodash';

export class PdfResponse {
  id: string;
  referenceDrug: DrugProfile;
  drugsRankByAmount: DrugProfile[];
  drugsRankByPercent: DrugProfile[];

  constructor(data?: PdfResponse) {
      _.assignIn(this, data);
  }
}

export class PdfRequest {
  id: string;
  printedDate: string;

  constructor(data?: PdfRequest) {
    _.assignIn(this, data);
}
}
