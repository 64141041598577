import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments';

@Injectable({
  providedIn: 'root'
})
export class ListsService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  public getHowReimbursedList() {
    return this.http.get(`${this.baseUrl}/lists/howReimbursed`);
  }

  public getContractualAllowancesDeductionsList() {
    return this.http.get(`${this.baseUrl}/lists/contractualAllowancesDeductions`);
  }

  public getE340BCoveredEntityList() {
    return this.http.get(`${this.baseUrl}/lists/e340BCoveredEntity`);
  }

  public getPayers() {
    return this.http.get(`${this.baseUrl}/lists/payers`);
  }

  public getCostBasis() {
    return this.http.get(`${this.baseUrl}/lists/cost-basis`);
  }

}
