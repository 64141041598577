import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpParams,
  HttpParameterCodec
} from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class EncodeURIInterceptor implements HttpInterceptor {

  constructor() { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newRequest: HttpRequest<any>;
    let newHttpParams = new HttpParams({ encoder: new CustomEncoder() });
    request.params.keys().forEach((key: string) => {
      const value = request.params.get(key);
      if (value) {
        newHttpParams = newHttpParams.append(key, value);
      }
    });
    newRequest = request.clone({
      params: newHttpParams
    });
    return next.handle(newRequest);
  }
}

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
