import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

import { TextMaskModule } from 'angular2-text-mask';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TextMaskModule,
    BrowserAnimationsModule,
    RouterModule,
    AngularFontAwesomeModule,
    NgbModule,
    ClipboardModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
  ],
  exports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    TextMaskModule,
    AngularFontAwesomeModule,
    NgbModule,
    ClipboardModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
  ],
  declarations: []
})
export class SharedModule { }
