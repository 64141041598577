import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { WINDOW_PROVIDERS } from './providers';
import { EncodeURIInterceptor } from './interceptors';
import { SharedModule } from '../shared';

@NgModule({
  imports: [
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeURIInterceptor,
      multi: true,
    },
    WINDOW_PROVIDERS,
  ],
  declarations: [
  ],
  exports: [
  ]
})
export class CoreModule {}
