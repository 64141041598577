import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable, forkJoin } from 'rxjs';

import { DrugProfile, PdfResponse } from '@core/models';
import { ListResponse } from '@core/interfaces';
import { CalculateService, ListsService } from '@core/services';
import { ContractualAllowancesDeductions } from '@shared/utils';


@Component({
  selector: 'app-dcal-drug-margin-pdf',
  templateUrl: './drug-margin-pdf.component.html',
  styleUrls: ['./drug-margin-pdf.component.scss']
})
export class DrugMarginPdfComponent implements OnInit {

  sessionId: string;
  referenceDrug = new DrugProfile();
  drugsRankByAmount: DrugProfile[] = [];
  drugsRankByPercent: DrugProfile[] = [];

  contractualAllowancesDeductionsList: ListResponse[] = [];
  payers: ListResponse[] = [];
  costBasisList: ListResponse[] = [];

  isShowBilledUnits = false;
  totalBilledUnits = 0;

  private getResources: Observable<any[]>;

  constructor(
    private calculateService: CalculateService,
    private listsService: ListsService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.sessionId = params['id'];
    });
  }

  ngOnInit() {
    // get data to export pdf
    if (this.sessionId) {
      this.calculateService.getDataToGeneratePdf(this.sessionId).subscribe((pdfResult: PdfResponse) => {
        this.referenceDrug = pdfResult.referenceDrug;
        this.drugsRankByAmount = pdfResult.drugsRankByAmount;
        this.drugsRankByPercent = pdfResult.drugsRankByPercent;
        if (this.referenceDrug.contractualAllowancesDeductions === ContractualAllowancesDeductions.BILLED_UNITS_BY_PAYER) {
          this.isShowBilledUnits = true;
          this.referenceDrug.payerData.forEach(payer => {
            this.totalBilledUnits += +payer.payerMixOrBilledUnits;
          });
        }
      });
    }

    // get default list
    const getContractualAllowancesDeductionsList = this.listsService.getContractualAllowancesDeductionsList();
    const getPayers = this.listsService.getPayers();
    const getCostBasis = this.listsService.getCostBasis();
    this.getResources = forkJoin([getContractualAllowancesDeductionsList, getPayers, getCostBasis]);
    this.getResources.subscribe((result: any[]) => {
      this.contractualAllowancesDeductionsList = result[0];
      this.payers = result[1];
      this.costBasisList = result[2];
    });
  }

  // get ContractualAllowancesDeductions name based on ContractualAllowancesDeductions id
  getContractualAllowancesDeductionsName(key: string) {
    if (this.contractualAllowancesDeductionsList.length) {
      for (let index = 0; index < this.contractualAllowancesDeductionsList.length; index++) {
        if (this.contractualAllowancesDeductionsList[index].id === key) {
          return this.contractualAllowancesDeductionsList[index].description;
        }
      }
    } else {
      return '';
    }
  }

  // get payer name based on payer id
  getPayerName(key: string) {
    if (this.payers.length) {
      for (let index = 0; index < this.payers.length; index++) {
        if (this.payers[index].id === key) {
          return this.payers[index].description;
        }
      }
      return key;
    } else {
      return '';
    }
  }

  // get cost basis based on id
  getCostBasisName(value: string) {
    if (this.costBasisList.length) {
      for (let index = 0; index < this.costBasisList.length; index++) {
        if (this.costBasisList[index].id === value) {
          return this.costBasisList[index].description;
        }
      }
    } else {
      return '';
    }
  }

}

