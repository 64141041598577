import { CalculatedResult } from './calculated-result.model';

export class CalculatedResponse {
  sessionId: string;
  calculatedResult: CalculatedResult;
  drugs: DrugProjection[];
  orderByDrugs: string;
}

export class DrugProjection {
  id: string;
  drugName: string;
  billedRevenue: number;
  receivableRevenue: number;
  cost: number;
  netMarginAmount: number;
  netMarginPercent: number;
  isReferenceDrug: boolean;
}
