export class E340BCoveredEntity {
  static readonly NO = 'NO';
  static readonly YES_CARVE_IN = 'YESCARVEIN';
  static readonly YES_CARVE_OUT = 'YESCARVEOUT';
}

export class ContractualAllowancesDeductions {
  static readonly PAYER_MIX_ONLY = 'PAYERMIXONLY';
  static readonly BILLED_UNITS_BY_PAYER = 'BILLEDUNITSBYPAYER';
}

export class HowReimbursed {
  static readonly FIXED = 'FIXED';
  static readonly PERCENT = 'PERCENT';
}

export class Payer {
  static readonly MEDICARE = 'MEDICARE';
  static readonly MEDICAID_CARVE_OUT = 'MEDICAIDCARVEOUT';
  static readonly MEDICAID_CARE_IN = 'MEDICAIDCAREIN';
  static readonly COMMERCIAL = 'COMMERCIAL';
  static readonly MANAGED_CARE = 'MANAGEDCARE';
  static readonly OTHER = 'OTHER';
}

export class CostBasis {
  static readonly THE340B = 'THE340B';
  static readonly WAC = 'WAC';
  static readonly GPO = 'GPO';
}

export class ValidationType {
  static readonly REQUIRED = 'required';
  static readonly MAXLENGTH = 'maxlength';
  static readonly PATTERN = 'pattern';
  static readonly MIN = 'min';
}

export const patternValidationMessages = {
  billedAmountOrPercent: '\'Billed Amount or %\' must be > 0% and <= 100%.',
  payerMixOrBilledUnits: 'Payer Mix must be > 0% and <= 100%.',
  totalPayerMixInvalid: 'Sum Total of Payer Mix must = 100%. Please make required adjustments.',
};

export const requiredValidationMessages = {
  the340bCoverEntity: '340B Cover Entity is required.',
  contractualAllowancesDeductions: 'Contractual Allowances & Deduction is required.',
  utilizationUnits: '# Utillization Units is required.',
  wac: '$WAC is required.',
  gpo: '$GPO is required.',
  the340b: '$340B is required.',
  priceCharge: '$Price/Charge is required.',
  drugName: 'Drug, route, dose and strength is required.',
  payer: 'Payer is required.',
  costBasis: 'Cost Basis is required.',
  howReimbursed: 'How Reimbursed is required.',
  billedAmountOrPercent: 'Billed Amount or % is required.',
  payerMix: 'Payer Mix is required.',
  billedUnits: 'Billed Units is required.',
  referenceDrug: 'Drug, Strength & Form is required.',
};

export const maxlengthValidationMessages = {
  utilizationUnits: '# Utillization Units cannot be more than 15 characters long.',
  drugName: 'Drug, route, dose and strength cannot be more than 255 characters long.',
  referenceDrug: 'Drug, Strength & Form cannot be more than 255 characters long.',
  priceCharge: '$Price/Charge cannot be more than 20 characters long.',
  the340b: '$340B cannot be more than 20 characters long.',
  wac: '$WAC cannot be more than 20 characters long.',
  gpo: '$GPO cannot be more than 20 characters long.',
  billedUnits: 'Billed Units cannot be more than 20 characters long.',
  billedAmountOrPercent: 'Billed Amount or % cannot be more than 20 characters long.',
};

export const minValidationMessages = {
  utilizationUnits: '# Utillization Units must be greater than zero.',
  payerMix: 'Payer Mix must be greater than zero.',
  billedUnits: 'Billed Units must be greater than zero.',
  billedAmountOrPercent: 'Billed Amount or % must be greater than zero.',
  priceCharge: '$Price/Charge must be greater than zero.',
  the340b: '$340B must be greater than zero.',
  wac: '$WAC must be greater than zero.',
  gpo: '$GPO must be greater than zero.',
};

export const percentPattern = /^(100(?:\.00?)?|\d?\d(?:\.\d\d?)?)%$/i;
export const maxlength20charsPattern = /^(.{1,20})$/i;
