import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments';
import { CalculatedResult, CalculatedResponse, PdfResponse, PdfRequest } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class CalculateService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  public calculate(data: CalculatedResult): Observable<CalculatedResponse> {
    return this.http.post<CalculatedResponse>(`${this.baseUrl}/dcal/calculate`, data);
  }

  public getCalculatedResult(id: string): Observable<CalculatedResponse> {
    return this.http.get<CalculatedResponse>(`${this.baseUrl}/dcal/get/${id}`);
  }

  public getDataToGeneratePdf(id: string): Observable<PdfResponse> {
    return this.http.get<PdfResponse>(`${this.baseUrl}/dcal/pdf/${id}`);
  }

  public download(request: PdfRequest) {
    return this.http.post(`${this.baseUrl}/dcal/download`, request, { responseType: 'arraybuffer' })
    .pipe(
      map( data => {
        return data;
    }));
  }

}
