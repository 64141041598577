import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, forkJoin } from 'rxjs';

import * as _ from 'lodash';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ListsService, CalculateService } from '@core/services';
import {
  CalculatedResult,
  CalculatedResponse,
  DrugProjection,
  DrugProfile,
  PayerData,
  PdfRequest,
} from '@core/models';
import {
  HowReimbursed,
  requiredValidationMessages,
  patternValidationMessages,
  maxlengthValidationMessages,
  minValidationMessages,
  getCurrencyMask,
  getPercentMask,
  getNumberMask,
  ContractualAllowancesDeductions,
  ValidationType,
  Payer,
  CostBasis,
  removePercentChar,
} from '@shared/utils';
import { WINDOW } from '@core/providers';
import {
  GreaterThanZeroValidator,
  PayerMixOrBilledUnitsValidator,
  BilledAmountOrPercentValidator,
  totalPayerMixValidator,
} from '@core/validators';
import { ListResponse } from '@core/interfaces';

@Component({
  selector: 'app-dcal-drug-margin-calculator',
  templateUrl: './drug-margin-calculator.component.html',
  styleUrls: ['./drug-margin-calculator.component.scss']
})

export class DrugMarginCalculatorComponent implements OnInit {

  calculatedResult: CalculatedResult;
  calculatedResponse: CalculatedResponse;

  payerDataForm: FormGroup;
  calculatedResultForm: FormGroup;
  organizationProfileForm: FormGroup;
  form: FormGroup;

  drugResultList: DrugProjection[] = [];
  howReimbursedList: ListResponse[] = [];
  contractualAllowancesDeductionsList: ListResponse[] = [];
  e340BCoveredEntityList: ListResponse[] = [];
  payers: ListResponse[] = [];
  costBasisList: ListResponse[] = [];
  listInvalidMessage: string[] = [];

  isShowInstruction = false;
  isShowUtilizationUnits = true;
  isFixed = true;
  isAddDrugClicked = false;
  isCalculateClicked = false;

  eHowReimbursed = HowReimbursed;

  sessionId: string;
  currencyMask: string;
  percentMask: string;
  numberMask: string;
  payerMixMask: string;
  lastCalculated: string;
  domain: string;
  selectedPayer = '';

  isThe340BRequired = false;
  isWacRequired = false;
  isGpoRequired = false;

  isOtherSelectedList: boolean[] = [];
  isPayerDropDownList = [ Payer.COMMERCIAL, Payer.MANAGED_CARE, Payer.MEDICAID_CARE_IN, Payer.MEDICAID_CARVE_OUT, Payer.MEDICARE ];

  removePercentChar = removePercentChar;

  validations = {
    required: requiredValidationMessages,
    pattern: patternValidationMessages,
    maxlength: maxlengthValidationMessages,
    min: minValidationMessages
  };

  private getResources: Observable<any[]>;

  @ViewChild('shareContent')
  private shareContent: TemplateRef<any>;

  @ViewChild('calculateContent')
  private calculateContent: TemplateRef<any>;

  @ViewChild('payerContent')
  private payerContent: TemplateRef<any>;

  @ViewChild('clearDataContent')
  private clearDataContent: TemplateRef<any>;

  @ViewChild('deleteDrug')
  private deleteDrug: TemplateRef<any>;

  @ViewChild('deletePayer')
  private deletePayer: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    private listsService: ListsService,
    private calculateService: CalculateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    @Inject(WINDOW) private window: Window,
  ) {
      this.domain = this.window.location ? this.window.location.origin : '';
      this.activatedRoute.params.subscribe(params => {
        this.sessionId = params['id'];
      });
  }

  ngOnInit() {
    this.initData();
  }

  // change UI if organizationProfileForm has changed
  onChanges(): void {
    this.organizationProfileForm.get('contractualAllowancesDeductions').valueChanges.subscribe(val => {
      this.isContractualAllowancesDeductionsChanged(val);
    });
    this.organizationProfileForm.get('utilizationUnits').valueChanges.subscribe(val => {
      this.isUtilizationUnitsChanged(val);
    });
    this.organizationProfileForm.get('payerData').valueChanges.subscribe(data => {
      this.isOrganizationPayerDataChanged(data);
    });
    this.organizationProfileForm.valueChanges.subscribe(v => {
      this.isCalculateClicked = false;
      if (this.organizationProfileForm.invalid) {
        this.getInvalidMessageForPayerData();
      }
    });
    this.onCostBasisChanged();
  }

  // submit to calculate
  onCalculate(): void {
    this.isCalculateClicked = true;
    if (this.form.valid) {
      // Reference Drug
      const referenceDrug = this.form.value.organizationProfileForm as DrugProfile;
      referenceDrug.payerData.map(p => {
        p.payerMixOrBilledUnits = p.payerMixOrBilledUnits.replace(/[$,]/g, '');
        p.billedAmountOrPercent = p.billedAmountOrPercent.replace(/[$,]/g, '');
        p.id = null;
        p.createdDate = null;
      });
      referenceDrug.priceCharge = referenceDrug.priceCharge.toString().replace(/[$,]/g, '');
      referenceDrug.utilizationUnits = referenceDrug.utilizationUnits.toString().replace(/[$,]/g, '');

      referenceDrug.the340b = referenceDrug.the340b.toString().replace(/[$,]/g, '') || null;
      referenceDrug.wac = referenceDrug.wac.toString().replace(/[$,]/g, '') || null;
      referenceDrug.gpo = referenceDrug.gpo.toString().replace(/[$,]/g, '') || null;

      referenceDrug.createdDate = null;
      referenceDrug.id = null;
      referenceDrug.isReferenceDrug = true;

      referenceDrug.drugName = referenceDrug.referenceDrug;

      // list drugs from Comparison Drug Profiles
      const drugs = [...this.form.value.drugsForm] as DrugProfile[];
      if (drugs.length > 0) {
        drugs.map(drug => {
          drug.the340b = drug.the340b ? drug.the340b.toString().replace(/[$,]/g, '') : null;
          drug.priceCharge = drug.priceCharge.toString().replace(/[$,]/g, '');
          drug.wac = drug.wac ? drug.wac.toString().replace(/[$,]/g, '') : null;
          drug.gpo = drug.gpo ? drug.gpo.toString().replace(/[$,]/g, '') : null;
          drug.utilizationUnits = drug.utilizationUnits.toString().replace(/[$,]/g, '');
          drug.payerData.map(p => {
            p.payerMixOrBilledUnits = p.payerMixOrBilledUnits.replace(/[$,]/g, '');
            p.billedAmountOrPercent = p.billedAmountOrPercent.replace(/[$,]/g, '');
          });
        });
        if (!this.calculatedResult) {
          this.calculatedResult = new CalculatedResult();
        }
        // add Reference Drug
        drugs.push(referenceDrug);
        // assign drugs to calculatedResult
        this.calculatedResult.drugs = drugs;
        this.calculatedResult.orderByDrugs = this.form.value.orderByDrugs;
        this.calculateService.calculate(this.calculatedResult)
          .subscribe(
            (result: CalculatedResponse) => {
              this.calculatedResponse = result;
              this.sessionId = result.sessionId;

              if (result.orderByDrugs === 'dollar') {
                this.drugResultList = result.drugs.sort((a, b) => b.netMarginAmount - a.netMarginAmount);
              } else {
                this.drugResultList = result.drugs.sort((a, b) => b.netMarginPercent - a.netMarginPercent);
              }

              this.calculatedResult = result.calculatedResult;
              this.lastCalculated = moment().format('LLL');
            });
      } else {
        this.modalService.open(this.calculateContent, {
          centered: true,
          keyboard: false,
          backdrop: 'static',
        });
      }
    } else if (!this.isAddDrugClicked) {
      this.isAddDrugClicked = true;
      this.getInvalidMessageForPayerData();
    }
  }

  // clear data
  onClearData() {
    this.modalService.open(this.clearDataContent, {
      centered: true,
      keyboard: false,
      backdrop: 'static',
    }).result.then(() => {
      if (this.router.url !== '/') {
        this.router.navigateByUrl('/');
      } else {
        this.calculatedResponse = null;
        this.sessionId = null;
        this.drugResultList = [];
        this.calculatedResult = null;
        this.lastCalculated = null;
        // re-init data
        this.initData();
      }
    }, () => {
      // do nothing
    });
  }

  // share action
  onShare(): void {
    this.modalService.open(this.shareContent, {
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: 'lg',
    });
  }

  // download action
  onDownload(): any {
    const pdfRequest = new PdfRequest();
    pdfRequest.id = this.sessionId;
    pdfRequest.printedDate = moment().format('DD/MM/YYYY HH:mm');

    this.calculateService.download(pdfRequest).subscribe((result: any) => {
      // download file
      const file = new Blob([result], {
        type: 'application/pdf'
      });
      const url = this.window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      a.href = url;
      a.download = this.sessionId + '_' + moment().format('DD/MM/YYYY HH:mm') + '.pdf';
      a.click();
      return url;
    });
  }

  // close instruction
  onCloseInstruction(): void {
    this.isShowInstruction = true;
  }

  // add new an item payerDataForm of organizationProfileForm
  onAddPayerDataForm(): void {
    this.isAddDrugClicked = false;
    this.isCalculateClicked = false;
    const control = <FormArray>this.organizationProfileForm.controls['payerData'];
    control.push(this.initPayerDataForm());
  }

  // remove an item in payerDataForm of organizationProfileForm
  onRemovePayerDataForm(index: number): void {
    this.modalService.open(this.deletePayer, {
      centered: true,
      keyboard: false,
      backdrop: 'static',
    }).result.then(() => {
      this.isAddDrugClicked = false;
      this.isCalculateClicked = false;
      const control = <FormArray> this.organizationProfileForm.controls['payerData'];
      control.removeAt(index);
    }, () => {
      // do nothing
    });
  }

  // add new an item to drugs form
  onAddDrugForm(): void {
    this.isAddDrugClicked = true;
    this.isCalculateClicked = false;
    if (this.organizationProfileForm.valid) {
      const control = <FormArray> this.form.controls['drugsForm'];
      control.push(this.initDrugsForm());
    } else {
      this.validateAllFormFields(this.organizationProfileForm);
      this.getInvalidMessageForPayerData();
    }
  }

  // remove an item to drugs form
  onRemoveDrugForm(index: number): void {
    this.modalService.open(this.deleteDrug, {
      centered: true,
      keyboard: false,
      backdrop: 'static',
    }).result.then(() => {
      const control = <FormArray> this.form.controls['drugsForm'];
      control.removeAt(index);
    }, () => {
      // do nothing
    });
  }

  // get payer name based on id
  getPayerName(value: string): string {
    if (this.payers.length) {
      for (let index = 0; index < this.payers.length; index++) {
        if (this.payers[index].id === value) {
          return this.payers[index].description;
        }
      }
      return value;
    } else {
      return '';
    }
  }

  // get cost basis based on id
  getCostBasisName(value: string): string {
    if (this.costBasisList.length) {
      for (let index = 0; index < this.costBasisList.length; index++) {
        if (this.costBasisList[index].id === value) {
          return this.costBasisList[index].description;
        }
      }
    } else {
      return '';
    }
  }

  // get howReimbursed name based on id
  getHowReimbursed(value: string): string {
    if (this.howReimbursedList.length) {
      for (let index = 0; index < this.howReimbursedList.length; index++) {
        if (this.howReimbursedList[index].id === value) {
          return this.howReimbursedList[index].description;
        }
      }
    } else {
      return '';
    }
  }

  // rank order by
  orderByChanged(): void {
    if (this.form.value.orderByDrugs === 'percent') {
      this.drugResultList.sort((a, b) => b.netMarginPercent - a.netMarginPercent);
    } else {
      this.drugResultList.sort((a, b) => b.netMarginAmount - a.netMarginAmount);
    }
  }

  // get detail error message
  getInvalidMessageForPayerData(): void {
    this.listInvalidMessage = [];
    const arrayControls = ( <FormArray> this.organizationProfileForm.controls['payerData']).controls;

    for (let index = 0; index < arrayControls.length; index++) {
      const controls = ( <FormGroup> arrayControls[index]).controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          // tslint:disable-next-line:forin
          for (const error in controls[name].errors) {
            const msg = this.getErrorMessage(error, name);
            this.listInvalidMessage.push(msg);
          }
        }
      }
    }
    const unique = (value, index, self) => {
      return self.indexOf(value) === index;
    };
    this.listInvalidMessage = this.listInvalidMessage.filter(unique);
  }

  // get list error message from payer data group of each drug
  getInvalidMessageForPayerDataOfDrug(arrayControl: AbstractControl): string[] {
    const errors: string[] = [];
    const arrayControls = (<FormArray> arrayControl).controls;
    for (let index = 0; index < arrayControls.length; index++) {
      const controls = (<FormGroup> arrayControls[index]).controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          // tslint:disable-next-line: forin
          for (const error in controls[name].errors) {
            const msg = this.getErrorMessage(error, name);
            errors.push(msg);
          }
        }
      }
    }
    const unique = (value, index, self) => {
      return self.indexOf(value) === index;
    };
    return errors.filter(unique);
  }
  // get controls method
  get payerData() {
    return <FormArray > this.organizationProfileForm.get('payerData');
  }

  get drugsForm() {
    return <FormArray > this.form.get('drugsForm');
  }

  // don't allows select duplicate payer in payer data
  onPayerChanged(value: string, i: number): void {
    if (value && value !== Payer.OTHER) {
      const payers = this.organizationProfileForm.get('payerData').value;
      for (let index = 0; index < payers.length; index++) {
        if (index !== i) {
          if (payers[index].payer === value) {
            ( <FormArray> this.organizationProfileForm.get('payerData')).controls[i].get('payer').markAsTouched({
              onlySelf: false
            });
            this.selectedPayer = this.payers.find(x => x.id === value).description;
            this.modalService.open(this.payerContent, {
              centered: true,
              keyboard: false,
              backdrop: 'static',
              size: 'lg',
            }).result.then(() => {
              // do nothing
            }, () => {
              ( <FormArray> this.organizationProfileForm.get('payerData')).controls[i].get('payer').setValue(null);
            });
          }
        }
      }
    }
  }

  onCostBasisChanged(): void {
    const selectedCostBasisList = this.organizationProfileForm.get('payerData').value;
    this.isThe340BRequired = selectedCostBasisList.some(item => item.costBasis === CostBasis.THE340B);
    this.isWacRequired = selectedCostBasisList.some(item => item.costBasis === CostBasis.WAC);
    this.isGpoRequired = selectedCostBasisList.some(item => item.costBasis === CostBasis.GPO);
  }

  onSwitchToDropDown(i: number): void {
    (<FormArray>this.organizationProfileForm.get('payerData')).controls[i].get('payer').setValue(null);
  }

  private initData(): void {
    this.isAddDrugClicked = false;
    this.isCalculateClicked = false;
    this.isShowInstruction = false;
    this.isShowUtilizationUnits = true;
    // this.isYesCarveIn = false;
    this.isFixed = true;
    // get default mask to set textMask for input
    this.currencyMask = getCurrencyMask();
    this.percentMask = getPercentMask();
    this.numberMask = getNumberMask();
    this.payerMixMask = this.percentMask; // by default ContractualAllowancesDeductionsList === PayerMix
    // get default list value
    const getHowReimbursedList = this.listsService.getHowReimbursedList();
    const getContractualAllowancesDeductionsList = this.listsService.getContractualAllowancesDeductionsList();
    const getCostBasis = this.listsService.getCostBasis();
    const getPayers = this.listsService.getPayers();
    this.getResources = forkJoin([getHowReimbursedList, getContractualAllowancesDeductionsList, getPayers, getCostBasis]);
    this.getResources.subscribe((result: any[]) => {
      this.howReimbursedList = result[0];
      this.contractualAllowancesDeductionsList = result[1];
      this.payers = result[2];
      this.costBasisList = result[3];
    });
    // create form by default
    this.createForm();
    // register onChanges event
    this.onChanges();
    // if have session id --> get data of this session and create form again to set default data
    if (this.sessionId) {
      this.calculateService.getCalculatedResult(this.sessionId).subscribe((result: CalculatedResponse) => {
        this.calculatedResult = result.calculatedResult;
        // tslint:disable-next-line:max-line-length
        const isPayerMix = this.calculatedResult.drugs[0].contractualAllowancesDeductions === ContractualAllowancesDeductions.PAYER_MIX_ONLY;
        this.isShowUtilizationUnitsChanged(isPayerMix);

        if (result.orderByDrugs === 'dollar') {
          this.drugResultList = result.drugs.sort((a, b) => b.netMarginAmount - a.netMarginAmount);
        } else {
          this.drugResultList = result.drugs.sort((a, b) => b.netMarginPercent - a.netMarginPercent);
        }

        if (this.calculatedResult.updatedDate) {
          this.lastCalculated = moment(this.calculatedResult.updatedDate).format('LLL');
        }
        // set default data
        this.createForm();
        // register onChanges event again
        this.onChanges();
      });
    }
  }

  // update utilizationUnits value for drugs form
  private isUtilizationUnitsChanged(value: string): void {
    this.setValueForControlInDrugsFrom('utilizationUnits', value);
  }

  // update contractualAllowancesDeductions value for drugs form
  // also show/hide UtilizationUnits input
  private isContractualAllowancesDeductionsChanged(value: string): void {
    const isPayerMix = value === ContractualAllowancesDeductions.PAYER_MIX_ONLY;
    this.isShowUtilizationUnitsChanged(isPayerMix);
    this.setValueForControlInDrugsFrom('contractualAllowancesDeductions', value);
    // update model for organizationProfileForm
    const payerDataArr = <FormArray> this.organizationProfileForm.get('payerData');
    for (let index = 0; index < payerDataArr.length; index++) {
      const payerMixOrBilledUnitsData = (<FormArray>this.organizationProfileForm.get('payerData'))
                                                        .controls[index].get('payerMixOrBilledUnits').value;
      if (isPayerMix) {
        ( <FormArray> this.organizationProfileForm.get('payerData'))
                          .controls[index].get('payerMixOrBilledUnits').setValue(payerMixOrBilledUnitsData + '%');
      } else {
        ( <FormArray> this.organizationProfileForm.get('payerData'))
                          .controls[index].get('payerMixOrBilledUnits').setValue(payerMixOrBilledUnitsData.replace(/[%]/g, ''));
      }
    }
    // re-set data for payer data of drugs
    const payerData = this.organizationProfileForm.value.payerData as PayerData[];
    this.isOrganizationPayerDataChanged(payerData);
  }

  // show UtilizationUnits or not and update text mask for payer mix input
  private isShowUtilizationUnitsChanged(isPayerMix: boolean): void {
    this.isAddDrugClicked = false;
    this.isShowUtilizationUnits = isPayerMix;
    this.payerMixMask = isPayerMix ? this.percentMask : this.numberMask;
  }

  // update drug model if organization is changed
  private isOrganizationPayerDataChanged(organizationPayerData: PayerData[]): void {
    this.isAddDrugClicked = false;
    for (let index = 0; index < organizationPayerData.length; index++) {
      this.isOtherSelectedList[index] = true;
      if (organizationPayerData[index].payer == null || this.isPayerDropDownList.includes(organizationPayerData[index].payer)) {
        this.isOtherSelectedList[index] = false;
      }
    }

    if (organizationPayerData) {
      const drugs = this.form.value.drugsForm as DrugProfile[];
      if (drugs) {
        for (let index = 0; index < drugs.length; index++) {
          const drugPayerDataArray = ( < FormArray > this.form.controls['drugsForm']).controls[index].get('payerData') as FormArray;
          // remove all payer data from drug
          for (let i = 0; i < drugPayerDataArray.length; i++) {
            while (drugPayerDataArray.length !== 0) {
              drugPayerDataArray.removeAt(0);
            }
          }
          // re-create the drug payer data based organization data
          for (let i = 0; i < organizationPayerData.length; i++) {
            if (organizationPayerData[i].payer !== '' && organizationPayerData[i].payerMixOrBilledUnits !== '') {
              const _group = this.fb.group({
                id: null,
                payer: organizationPayerData[i].payer,
                costBasis: organizationPayerData[i].costBasis,
                payerMixOrBilledUnits: organizationPayerData[i].payerMixOrBilledUnits,
                howReimbursed: organizationPayerData[i].howReimbursed,
                billedAmountOrPercent: [
                  organizationPayerData[i].howReimbursed === HowReimbursed.FIXED ? '' : organizationPayerData[i].billedAmountOrPercent,
                  [
                    Validators.required,
                    BilledAmountOrPercentValidator(),
                  ]
                ],
                createdDate: null
              });
              drugPayerDataArray.push(_group);
            }
          }
        }
      }
    }
  }

  /*
    * Create form for this page
    */
  private createForm(): void {
    if (this.calculatedResult) {
      // get data of first drug to set default value to the organizationProfileForm
      // because in a calculatedResult, all drugs have the same organizationProfile data
      const referenceDrug = this.calculatedResult.drugs.find(d => d.isReferenceDrug === true);
      // create organizationProfileForm
      this.organizationProfileForm = this.fb.group({
        contractualAllowancesDeductions: [
          referenceDrug ? (referenceDrug.contractualAllowancesDeductions || '') : '',
          [Validators.required],
        ],
        utilizationUnits: [
          referenceDrug ? (referenceDrug.utilizationUnits || '') : '',
          [Validators.maxLength(15), GreaterThanZeroValidator()],
        ],
        referenceDrug: [
          referenceDrug ? (referenceDrug.referenceDrug || '') : '',
          [Validators.maxLength(255), Validators.required],
        ],
        wac: [
          referenceDrug ? (referenceDrug.wac || '') : '',
          [Validators.required, Validators.maxLength(20), GreaterThanZeroValidator()],
        ],
        gpo: [
          referenceDrug ? (referenceDrug.gpo || '') : '',
          [Validators.required, Validators.maxLength(20), GreaterThanZeroValidator()],
        ],
        the340b: [
          referenceDrug ? (referenceDrug.the340b || '') : '',
          [Validators.required, Validators.maxLength(20), GreaterThanZeroValidator()],
        ],
        priceCharge: [
          referenceDrug ? (referenceDrug.priceCharge || '') : '',
          [Validators.required, Validators.maxLength(20), GreaterThanZeroValidator()],
        ],
        payerData: new FormArray([])
      }, {
        validator: [ totalPayerMixValidator ]
      });
      // create payerDataGroup to set to organizationProfileForm
      const payerDataGroup = referenceDrug.payerData.map(p => {
        const _billedAmountOrPercent = p.billedAmountOrPercent;
        return this.fb.group({
          payer: [p.payer, [Validators.required]],
          costBasis: [p.costBasis, [Validators.required]],
          payerMixOrBilledUnits: [p.payerMixOrBilledUnits,
            [
              Validators.required,
              PayerMixOrBilledUnitsValidator(),
              GreaterThanZeroValidator()
            ]
          ],
          howReimbursed: [p.howReimbursed, [Validators.required]],
          billedAmountOrPercent: [_billedAmountOrPercent, [Validators.required, BilledAmountOrPercentValidator()]]
        });
      });

      const payerDataArray: FormArray = this.fb.array(payerDataGroup);
      this.organizationProfileForm.setControl('payerData', payerDataArray);


      for (let index = 0; index < referenceDrug.payerData.length; index++) {
        this.isOtherSelectedList[index] = true;
        if (this.isPayerDropDownList.includes(referenceDrug.payerData[index].payer)) {
          this.isOtherSelectedList[index] = false;
        }
      }

      // create form
      this.form = this.fb.group({
        organizationProfileForm: this.organizationProfileForm,
        drugsForm: this.fb.array([]),
        orderByDrugs: [this.calculatedResult.orderByDrugs || 'dollar']
      });

      // set default for drugsForm
      const control = <FormArray> this.form.controls['drugsForm'];
      this.calculatedResult.drugs.filter(d => d.isReferenceDrug === false).forEach(drug => {
        const _drugFrm = this.fb.group({
          id: drug.id,
          drugName: [drug.drugName, [Validators.required, Validators.maxLength(255)]],
          wac: [drug.wac || '', [Validators.maxLength(20), GreaterThanZeroValidator()]],
          gpo: [drug.gpo || '', [Validators.maxLength(20), GreaterThanZeroValidator()]],
          the340b: [drug.the340b || '', [Validators.maxLength(20), GreaterThanZeroValidator()]],
          contractualAllowancesDeductions: [drug.contractualAllowancesDeductions],
          utilizationUnits: [drug.utilizationUnits],
          priceCharge: [drug.priceCharge, [Validators.required, Validators.maxLength(20), GreaterThanZeroValidator()]],
          referenceDrug: [drug.referenceDrug],
          isReferenceDrug: [drug.isReferenceDrug],
          createdDate: drug.createdDate,
          payerData: new FormArray([])
        });

        const drugPayerGroup = drug.payerData.map(p => {
          return this.fb.group({
            id: p.id,
            payer: p.payer,
            costBasis: p.costBasis,
            payerMixOrBilledUnits: p.payerMixOrBilledUnits,
            howReimbursed: p.howReimbursed,
            billedAmountOrPercent: [p.billedAmountOrPercent || '', [
              Validators.required,
              BilledAmountOrPercentValidator(),
            ]],
            createdDate: p.createdDate
          });
        });

        const drugPayerArray: FormArray = this.fb.array(drugPayerGroup);
        _drugFrm.setControl('payerData', drugPayerArray);
        control.push(_drugFrm);
      });
    } else { // create form without default data
      this.organizationProfileForm = this.fb.group({
        contractualAllowancesDeductions: [ContractualAllowancesDeductions.PAYER_MIX_ONLY || '', [Validators.required]],
        utilizationUnits: ['', [Validators.maxLength(15), GreaterThanZeroValidator()]],
        referenceDrug: ['', [Validators.maxLength(255), Validators.required]],
        wac: ['', [Validators.maxLength(20), GreaterThanZeroValidator()]],
        gpo: ['', [Validators.maxLength(20), GreaterThanZeroValidator()]],
        the340b: ['', [Validators.maxLength(20), GreaterThanZeroValidator()]],
        priceCharge: ['', [Validators.required, Validators.maxLength(20), GreaterThanZeroValidator()]],
        payerData: this.fb.array([
          this.initPayerDataForm()
        ])
      }, {
        validator: [ totalPayerMixValidator ]
      });

      this.form = this.fb.group({
        organizationProfileForm: this.organizationProfileForm,
        drugsForm: this.fb.array([]),
        orderByDrugs: ['dollar']
      });
    }
  }

  // init payerDataForm for organizationProfileForm
  private initPayerDataForm(): FormGroup {
    return this.fb.group({
      payer: [null, [Validators.required]],
      costBasis: ['', [Validators.required]],
      payerMixOrBilledUnits: ['', [Validators.required, PayerMixOrBilledUnitsValidator(), GreaterThanZeroValidator()]],
      howReimbursed: [HowReimbursed.FIXED, [Validators.required]],
      billedAmountOrPercent: ['', [Validators.required, BilledAmountOrPercentValidator()]]
    });
  }

  // init drugs form
  private initDrugsForm(): FormGroup {
    const organizationProfile = this.organizationProfileForm.value;
    const _drugFrm = this.fb.group({
      id: null,
      drugName: ['', [Validators.required, Validators.maxLength(255)]],
      wac: ['', [Validators.maxLength(20), GreaterThanZeroValidator()]],
      gpo: ['', [Validators.maxLength(20), GreaterThanZeroValidator()]],
      the340b: ['', [Validators.maxLength(20), GreaterThanZeroValidator()]],
      contractualAllowancesDeductions: [organizationProfile.contractualAllowancesDeductions],
      utilizationUnits: [organizationProfile.utilizationUnits],
      priceCharge: ['', [Validators.required, Validators.maxLength(20), GreaterThanZeroValidator()]],
      referenceDrug: [''],
      isReferenceDrug: [false],
      createdDate: null,
      payerData: new FormArray([])
    });

    // init payer data based on payer data of organization
    const organizationPayerData = this.organizationProfileForm.value.payerData;

    const payerDataGroup = organizationPayerData.map(p => {
      return this.fb.group({
        id: null,
        payer: p.payer,
        costBasis: p.costBasis,
        payerMixOrBilledUnits: p.payerMixOrBilledUnits,
        howReimbursed: p.howReimbursed,
        billedAmountOrPercent: [
          p.howReimbursed === HowReimbursed.FIXED ? '' : p.billedAmountOrPercent,
          [
            Validators.required,
            BilledAmountOrPercentValidator(),
          ]
        ],
        createdDate: null
      });
    });

    const payerDataArray: FormArray = this.fb.array(payerDataGroup);
    _drugFrm.setControl('payerData', payerDataArray);
    return _drugFrm;

  }

  // set value for control of drugsForm
  private setValueForControlInDrugsFrom(controlName: string, value: string): void {
    const drugs = this.form.value.drugsForm as DrugProfile[];
    if (drugs) {
      for (let index = 0; index < drugs.length; index++) {
        (<FormArray>this.form.controls['drugsForm']).controls[index].get(controlName).setValue(value);
      }
    }
  }

  // force validate a form
  private validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({
          onlySelf: true
        });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        Object.keys(control.controls).forEach(c => {
          const _subControl = control.get(c);
          if (_subControl instanceof FormControl) {
            _subControl.markAsTouched({
              onlySelf: true
            });
          } else if (_subControl instanceof FormGroup) {
            this.validateAllFormFields(_subControl);
          }
        });
      }
      formGroup.markAsTouched({
        onlySelf: true
      });
    });
  }

  private getErrorMessage(error: string, name: string): string {
    switch (error) {
      case ValidationType.REQUIRED: {
        return this.getRequiredMsgByControlName(name);
      }
      case ValidationType.MAXLENGTH: {
        return this.getMaxLengthMsgByControlName(name);
      }
      case ValidationType.PATTERN: {
        return this.getPatternMsgByControlName(name);
      }
      case ValidationType.MIN: {
        return this.getMinMsgByControlName(name);
      }
    }
  }

  private getMinMsgByControlName(name: string): string {
    switch (name) {
      case 'billedAmountOrPercent': {
        return this.validations.min.billedAmountOrPercent;
      }
      case 'payerMixOrBilledUnits': {
        if (this.isShowUtilizationUnits) {
          return this.validations.min.payerMix;
        } else {
          return this.validations.min.billedUnits;
        }
      }
    }
  }

  private getPatternMsgByControlName(name: string): string {
    switch (name) {
      case 'billedAmountOrPercent': {
        return this.validations.pattern.billedAmountOrPercent;
      }
      case 'payerMixOrBilledUnits': {
        if (this.isShowUtilizationUnits) {
          return this.validations.pattern.payerMixOrBilledUnits;
        } else {
          return this.validations.maxlength.billedUnits;
        }
      }
    }
  }

  private getRequiredMsgByControlName(name: string): string {
    switch (name) {
      case 'payer': {
        return this.validations.required.payer;
      }
      case 'costBasis': {
        return this.validations.required.costBasis;
      }
      case 'howReimbursed': {
        return this.validations.required.howReimbursed;
      }
      case 'billedAmountOrPercent': {
        return this.validations.required.billedAmountOrPercent;
      }
      case 'payerMixOrBilledUnits': {
        if (this.isShowUtilizationUnits) {
          return this.validations.required.payerMix;
        } else {
          return this.validations.required.billedUnits;
        }
      }
    }
  }

  private getMaxLengthMsgByControlName(name: string): string {
    switch (name) {
      case 'billedAmountOrPercent': {
        return this.validations.maxlength.billedAmountOrPercent;
      }
      case 'payerMixOrBilledUnits': {
        return this.validations.maxlength.billedUnits;
      }
    }
  }

}
