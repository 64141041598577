import * as _ from 'lodash';
import { PayerData } from './payer-data';

export class DrugProfile {
    id: string;
    drugName: string;
    contractualAllowancesDeductions: string;
    utilizationUnits: string;
    wac: string;
    gpo: string;
    the340b: string;
    priceCharge: string;
    billedRevenue: string;
    receivableRevenue: string;
    cost: string;
    netMarginAmount: string;
    netMarginPercent: string;
    referenceDrug: string;
    isReferenceDrug: boolean;
    payerData: PayerData[];
    createdDate: Date;
    updatedDate: Date;

    constructor(data?: DrugProfile) {
        _.assignIn(this, data);
    }
}
