import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export function getCurrencyMask() {
  const currencyMask = createNumberMask({
        prefix: '$',
        includeThousandsSeparator: true,
        allowDecimal: true,
        allowLeadingZeroes: false,
    });
    return currencyMask;
}

export function getPercentMask() {
  const percentMask = createNumberMask({
        prefix: '',
        suffix: '%',
        includeThousandsSeparator: true,
        allowDecimal: true,
        allowLeadingZeroes: false,
    });
    return percentMask;
}

export function getNumberMask() {
  const numberMask = createNumberMask({
        prefix: '',
        suffix: '',
        includeThousandsSeparator: true,
        allowDecimal: false,
        allowLeadingZeroes: false,
    });
    return numberMask;
}

export function removePercentChar(data: string): string {
  if (data == null) {
    return '';
  }
  if (data.endsWith('%')) {
    return data.replace(/[%,]/g, '');
  }
  return data.replace(/[,]/g, '');
}
