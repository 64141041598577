import * as _ from 'lodash';

export class PayerData {
    id: string;
    payer: string;
    costBasis: string;
    payerMixOrBilledUnits: string;
    howReimbursed: string;
    billedAmountOrPercent: string;
    createdDate: Date;
    updatedDate: Date;

    constructor(data?: PayerData) {
        _.assignIn(this, data);
    }
}
