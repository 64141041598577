import { AbstractControl } from '@angular/forms';
import { ContractualAllowancesDeductions } from '@shared/utils';

export const totalPayerMixValidator = (control: AbstractControl): {
  [key: string]: boolean
} => {
  const payerData = control.get('payerData').value;
  const contractualAllowancesDeductions = control.get('contractualAllowancesDeductions').value;
  if (contractualAllowancesDeductions === ContractualAllowancesDeductions.BILLED_UNITS_BY_PAYER) {
    return null;
  }
  let totalPayerMix = 0.0;

  for (let index = 0; index < payerData.length; index++) {
    const payerMix = payerData[index].payerMixOrBilledUnits ?
      payerData[index].payerMixOrBilledUnits.toString().replace(/[$,%]/g, '') :
      null;
    totalPayerMix += +payerMix;
  }

  return totalPayerMix <= 100 ? null : {
    totalPayerMixInvalid: true
  };
};

