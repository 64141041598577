import {
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';

export function GreaterThanZeroValidator(): ValidatorFn {
  return (control: AbstractControl): {
    [key: string]: any
  } => {
    const currentValue = control.value ? control.value.toString().replace(/[$,%]/g, '') : null;
    if (currentValue) {
      const number = +currentValue;
      return number > 0 ? null : {
        'min': true
      };
    }
    return null;
  };
}
