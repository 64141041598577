import * as _ from 'lodash';

import { DrugProfile } from './drug-profile.model';

export class CalculatedResult {
    id: string;
    drugs: DrugProfile[];
    orderByDrugs: string;
    createdDate: Date;
    updatedDate: Date;

    constructor(data?: CalculatedResult) {
        _.assignIn(this, data);
    }
}
